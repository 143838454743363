/**
 * Handles the update of the service worker by skipping the waiting phase and 
 * immediately activating the new service worker, even if there are still open 
 * pages controlled by the old service worker.
 * 
 * This ensures that the new service worker takes control of the page and 
 * replaces any cached assets with the ones specified in its cache manifest, 
 * effectively invalidating the old cache.
 */
export const onServiceWorkerUpdateReady = () => {
    navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' })

    console.log('New service worker version is installed.')

    window.location.reload()
}

/**
 * Handles smooth scrolling to anchor links on route update.
 */
export const onRouteUpdate = () => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.onclick = function (e) {
            const targetId = this.getAttribute('href')
            if (targetId && targetId.startsWith('#')) {
                const targetElement = document.querySelector(targetId)
                if (targetElement) {
                    e.preventDefault() // Prevent default jump

                    targetElement.scrollIntoView({ behavior: 'smooth' })

                    // Update the URL hash
                    window.history.pushState(null, '', targetId)
                }
            }
        }
    })
}
