module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_patch_hash=rn2vwxcssveh27vsmeul7m4h7a_babel-eslin_njeajqjdv3p4lbx7gpufkpxpdi/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4c43e119a8914069983b0e993d52516"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.0_patch_hash=rn2vwxcssveh27vsmeul7m4h7a_babel-eslint_qdtmtzouzeahdyghsturtyvyde/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.0_patch_hash=rn2vwxcssveh2_4qj6u22ayqnmn2nbqn24f4tnru/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_patch_hash=rn2vwxcssveh27vsmeul7m4h7a_babel-eslint@10.1.0_eslint@7.32.0__react-_wz6pw7qsmgroonzmx75thzdgy4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
